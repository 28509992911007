<template>
  <section>
    <div class="richtext">
      <h2><span style="font-size: 24px">Waste water pumps for sewage</span></h2>
      <p style="text-align: center"><br /></p>
      <p><br /></p>
      <p>
        Our waste water pumps&nbsp;can purify wastewater from household and
        business's sewage systems, which is also called sewage treatment plant,
        or STP for short. Meanwhile, there also increase more cases to tackle
        with the contaminated groundwater.
      </p>
      <p><br /></p>
      <p>
        According to the classification of sewage sources, it's generally
        divided into production sewage treatment and domestic sewage treatment.
        The production sewage treatment by using our sewage pump includes
        industrial sewage, agriculture sewage, medical sewage and so on, while
        the sewage generated in our daily life means domestic sewage. There are
        many more forms of the inorganic and organic complex mixture in the
        sewage. So our&nbsp;sewage water pumps are widely used to deal with them
        in order to make them available for dewatering and been reused in the
        world.<br /><br />
      </p>
      <h2><span style="font-size: 24px">Sewage water pump processes</span></h2>
      <p><br /></p>
      <p>The sewage treatment plant process is split into three grades.</p>
      <p>
        Primary treatment, which belongs to physical treatment, mainly removes
        solid pollution in a suspended state in sewage. Most of the physical
        treatment methods can only meet the primary requirements, but it's
        necessary. After primary treatment of sewage, BOD can generally be
        removed about 30%, not up to the discharge standard. Primary treatment
        is the pretreatment of secondary treatment.
      </p>
      <p><br /></p>
      <p>
        Secondary treatment, mainly to remove the colloidal and dissolved
        organic pollutants in sewage (BOD, COD), removal rate can reach more
        than 90% that can be generally discharged and used for irrigating. At
        this process, sewage transfer pump can be used to do tertiary treatment,
        which is the further treatment of the refractory organic matter,
        nitrogen, phosphorus and other soluble inorganic matter can lead to
        eutrophication of water.The main methods are biological nitrogen and
        phosphorus removal method, coagulation sedimentation method, sand
        filtration method, activated carbon adsorption method, ion exchange
        method and electroosmosis analysis method.<br /><br />
      </p>
      <p style="text-align: center">
        <img
          src="/uploads/image/20201123/Water-Treatment.jpg"
          title="Water-Treatment"
          alt="Water-Treatment"
          width="889"
          height="584"
          border="0"
          vspace="0"
          style="width: 889px; height: 584px"
        />
      </p>
      <p><br /></p>
      <p>
        The whole process for raw sewage through the coarse grid through the
        sewage pump after the ascension, flows through the grid or sand filter,
        and then enter the grit chamber, after sand water separation of
        wastewater into the settling basin for the first time, the above as the
        primary treatment, the pond water into biological processing equipment,
        at the beginning of the activated sludge and biological membrane method,
        (including aeration tank reactor of activated sludge method, oxidation
        ditch, etc., biological membrane method including biological filter,
        biological rotating disc, biological contact oxidation and biological
        fluidized bed), biological treatment of effluent into the secondary
        sedimentation tank, the second pond, by using water pump for sewage, the
        water is disinfected emissions or entering tertiary treatment, primary
        treatment to the end of the for secondary processing,The three
        treatments include biological nitrogen and phosphorus removal,
        coagulation and precipitation, sand filtration, activated carbon
        adsorption, ion exchange and electrodialysis. Part of the sludge from
        the secondary sedimentation tank goes back to the primary sedimentation
        tank or biological treatment equipment, part goes into the sludge
        concentration tank, and then into the sludge digestion tank. After
        dehydration and drying equipment, the sludge is finally used.
      </p>
      <p><br /></p>
    </div>
  </section>
</template>
<script>
export default {
  title: "Waste Water Treatment Pump",
}
</script>